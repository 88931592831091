.section-title-overline {
    border-top: 6px solid #a68555;
    width: 2rem;
    border-radius: 4px;
    align-self: center;
    margin: 0 0 .5rem;
  }
  
.section-divider {
    display: flex;
    justify-content: center;
  }
  
.divider-button {
    width: 2.5rem;
    height: .7rem;
    border-radius: 100px;
    border-color: rgb(32, 32, 32);
  }
  
.sections{
    /* border-top: black 1px solid; */
    margin: 0 4rem;
    padding: 1rem;
  }
  
.podcasts {
    margin: 2rem;
    /* padding: 0 2rem; */
  }
  
.podcast-card {
    height: 30rem;
    /* width: 15rem; */
    /* margin: 0; */
  }
  
.podcast-body{
   height: 20rem;
  }
  
.podcast-text {
    font-size: 17.5px;
  }
  
.story {
  
    height: 8rem;
    box-sizing: border-box;
    
  }

  .story-img{
    width: 100%;
  }
  
.story-text h3,
.story-text p{
    font-size: 1rem;
  }
  
.story-info{
    width: 100%;
    height: 7rem;
    margin-top: 1rem;
    /* box-shadow: 4px 30px 13px 6px rgb(117, 116, 116); */
  }
  
.story-info h1,
.story-info h4 { 
    font-size: .8rem;
    margin-right: .5rem;
  }
  
.card-img{
    height: 125%;
    width: 125%;
  }
  
.card-img-overlay h1, 
.card-img-overlay h4{
    font-size: 1.3rem;
    color: white;
    text-shadow: 2px 2px black;
    font-weight: bold;
  }
  
.featured-jumbo {
  display: block;
  max-width: 100%;
  height: auto;
} 

.home-jumbo {
  width: 100%;
}

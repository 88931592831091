.footer{
    height: 100%;
    overflow: hidden;
    width: 100%;
  }

.row {
  margin: 0
}
  
.mission-footer {
    font-family: 'Cormorant Garamond', serif;
    font-size: 1.3rem;;
  }

.social-icons-footer {
    height: 30px;
    width: 30px;
  }

.footer-form {
    position: relative;
    right: 26px;
    width: fit-content;
    height: 50rem;
  }

.socials-about {
    text-align: center;
  }
  
.social-icons-team {
    height: 30px;
    width: 30px;
  }
  
.social-icons-insta {
    height: 25px;
    width: 25px;
  }

.contact-form {
  align-items: center;
  margin: 10px;
}

.contact-firstname {
  padding-bottom: 10px;
}

.contact-email {
  padding-bottom: 10px;
}



.story {
  height: 8rem; 
  }
  
/* .story-text h3,
.story-text p{
    font-size: 1rem;
  } */
  
.story-info{
  width: 100%;
  height: 9rem;
  margin-top: 1rem;
  }
  /* box-shadow: 4px 30px 13px 6px rgb(117, 116, 116); */
  
  
.story-info h1,
.story-info h4 { 
  font-size: .8rem;
  margin-right: .5rem;
  } 
  

.card-img{
  height: 125%;
  width: 100%;
  }
  
.card-img-overlay h1, 
.card-img-overlay h4{
  font-size: 1.3rem;
  color: white;
  text-shadow: 2px 2px black;
  font-weight: bold;
  }
    




.thumbnail {
  position: relative;
}

.caption {
  position: absolute;
  top: 15%;
  left: 0;
  width: 100%;
  padding: 1rem;
}

.caption h1,
.caption h4 {
  font-size: 1.3rem;
  color: white;
  text-shadow: 2px 2px black;
  font-weight: bold;
}

.reviews-image {
  width: 100%;
  height: 9rem;
}